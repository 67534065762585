import { useSelector } from 'react-redux';
import { IRootState } from 'store/reducers';
import { SupportedThemes } from 'store/reducers/common';

const useTheme = () => {
  const theme: SupportedThemes = useSelector(
    (state: IRootState) => state.common.theme,
  );

  return theme;
};

export default useTheme;