import { PongLevel } from './common';
import { CB, DB, NA } from './commonBrickTypes';

export const PongLevel003: PongLevel = {
  number: 3,
  maxRowSize: 9,
  matrix: [
    [NA, NA, NA, NA, DB, NA, NA, NA, NA,],
    [NA, NA, NA, DB, CB, DB, NA, NA, NA,],
    [DB, DB, DB, CB, CB, CB, DB, DB, DB,],
    [NA, DB, CB, CB, CB, CB, CB, DB, NA,],
    [NA, NA, DB, CB, CB, CB, DB, NA, NA,],
    [NA, NA, NA, DB, CB, DB, NA, NA, NA,],
    [NA, NA, DB, CB, CB, CB, DB, NA, NA,],
    [NA, DB, CB, CB, CB, CB, CB, DB, NA,],
    [DB, DB, DB, CB, CB, CB, DB, DB, DB,],
    [NA, NA, NA, DB, CB, DB, NA, NA, NA,],
    [NA, NA, NA, NA, DB, NA, NA, NA, NA,],
  ],
};
