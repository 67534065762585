import { BlockType } from './common';

//NONE_BREAK
export const NA: BlockType = {
  type: 'none',
  colors: [
    {
      type: 'solid',
      beginColor: '#c1302c'
    },
  ],
  hits: 0,
  breaksOn: 0,
  points: [0],
};

//COMMON_BRICK
export const CB: BlockType = {
  type: 'normal',
  colors: [
    {
      type: 'gradient',
      beginColor: '#c1302c',
      middleColor: '#c0f637',
      endColor: '#8f4f4b',
    },
  ],
  hits: 0,
  breaksOn: 1,
  points: [10],
};

//DOUBLE_BRICK
export const DB: BlockType = {
  type: 'normal',
  colors: [
    {
      type: 'gradient',
      beginColor: '#55bfec',
      middleColor: '#9ee9d7',
      endColor: '#55bfec',
    },
    {
      type: 'gradient',
      beginColor: '#c1302c',
      middleColor: '#c0f637',
      endColor: '#8f4f4b',
    },
  ],
  hits: 0,
  breaksOn: 2,
  points: [10, 20],
};
