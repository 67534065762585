import { ReactNode } from 'react';
import HitItAnimalTile from './HitItAnimalTile';

export type TileTypeItem = {
  score: number,
  element: (size?: string) => ReactNode,
  rarity: {
    from: number,
    to: number
  }
}

export const TileType: TileTypeItem[] = [
  {
    score: 10,
    element: (size?: string) => <HitItAnimalTile size={size} animal='charm' />,
    rarity: {
      from: 1,
      to: 5000
    }
  },
  {
    score: 20,
    element: (size?: string) => <HitItAnimalTile size={size} animal='frog' />,
    rarity: {
      from: 5001,
      to: 6000
    }
  },
  {
    score: 30,
    element: (size?: string) => <HitItAnimalTile size={size} animal='snake' />,
    rarity: {
      from: 6001,
      to: 7000
    }
  },
  {
    score: 40,
    element: (size?: string) => <HitItAnimalTile size={size} animal='monkey' />,
    rarity: {
      from: 7001,
      to: 8000
    }
  },
  {
    score: 50,
    element: (size?: string) => <HitItAnimalTile size={size} animal='suricate' />,
    rarity: {
      from: 8001,
      to: 8500
    }
  },
  {
    score: 60,
    element: (size?: string) => <HitItAnimalTile size={size} animal='crocodile' />,
    rarity: {
      from: 8501,
      to: 9000
    }
  },
  {
    score: 70,
    element: (size?: string) => <HitItAnimalTile size={size} animal='rhino' />,
    rarity: {
      from: 9001,
      to: 9500
    }
  },
  {
    score: 80,
    element: (size?: string) => <HitItAnimalTile size={size} animal='elaphant' />,
    rarity: {
      from: 9501,
      to: 10000
    }
  },  
  {
    score: 90,
    element: (size?: string) => <HitItAnimalTile size={size} animal='giraffe' />,
    rarity: {
      from: 10001,
      to: 10500
    }
  },   
  {
    score: 100,
    element: (size?: string) => <HitItAnimalTile size={size} animal='leopard' />,
    rarity: {
      from: 10501,
      to: 11000
    }
  },   
  {
    score: 110,
    element: (size?: string) => <HitItAnimalTile size={size} animal='tiger' />,
    rarity: {
      from: 11001,
      to: 11500
    }
  },
  {
    score: 120,
    element: (size?: string) => <HitItAnimalTile size={size} animal='lion' />,
    rarity: {
      from: 11501,
      to: 11950
    }
  },
  {
    score: 200,
    element: (size?: string) => <HitItAnimalTile size={size} animal='unicorn' />,
    rarity: {
      from: 11950,
      to: 12000
    }
  } 
]