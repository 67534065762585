import { ENG_DICTIONARY } from './eng';
import { HEB_DICTIONARY } from './heb';

export type SupportedLangs = 'eng' | 'heb';
export type SupportedKeys =
  | 'inventory'
  | 'favorite_games'
  | 'racing'
  | 'puzzle'
  | 'stickers_album'
  | 'art'
  | 'score'
  | 'lifes'
  | 'start'
  | 'hit'
  | 'break'
  | 'collect'
  | 'next_level'
  | 'game_over'
  | 'start_over'
  | 'level'

type Dictionay = {
  [key in SupportedLangs]: LangDictionay;
};

export type LangDictionay = {
  [key in SupportedKeys]: string;
};

export const DICTIONARY: Dictionay = {
  eng: ENG_DICTIONARY,
  heb: HEB_DICTIONARY,
};
