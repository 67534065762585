import { lazy } from 'react';
import MainPanel from '../components/general/MainPanel';
import { SuspenseRoute } from './SuspenseRoute';

const HomePage = lazy(() => import('../pages/home/index'))
const TestPageTwo = lazy(() => import('../pages/TestPageTwo'))

const MainRoutes = {
  path: '/',
  element: <MainPanel />,
  children: [
    {
      path: '/',
      element:  <SuspenseRoute component={<HomePage />} />
    },
    {
      path: 'games',
      element: <SuspenseRoute component={<TestPageTwo />} />
    },
    {
      path: 'settings',
      element: <SuspenseRoute component={<TestPageTwo />} />
    },
    {
      path: 'profile',
      element: <SuspenseRoute component={<TestPageTwo />} />
    }    
  ]
};

export default MainRoutes;