import { createSlice } from '@reduxjs/toolkit';
import { calcBallSpeedBySize } from 'pages/games/Pong/common/physics';
import { PongLevel001 } from 'pages/games/Pong/game/levels/Level001';
import { PongLevel } from 'pages/games/Pong/game/levels/common';
import { levelsArray } from 'pages/games/Pong/game/levels/levelsArray';
import { PongState } from 'store/reducers/types/games/pong';

const isLevelComplete = (level: PongLevel) => {
  let totalBricks = 0;
  let brokenBricks = 0;

  for (let row = 0; row < level.matrix.length; row++) {
    for (let col = 0; col < level.matrix[row].length; col++) {
      totalBricks++;

      if (level.matrix[row][col].hits === level.matrix[row][col].breaksOn) {
        brokenBricks++;
      } else {
        break;
      }
    }
  }

  if (totalBricks === brokenBricks) {
    return true;
  } else {
    return false;
  }
};

const initialState: PongState = {
  status: 'welcome',
  score: 0,
  lifes: 3,
  level: PongLevel001,
  panel: {
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  },
  ball: {
    position: {
      top: 0,
      left: 0,
    },
    midPoint: {
      top: 0,
      left: 0,
    },
    radius: 0,
    speed: 6,
    direction: {
      top: 4,
      left: 4,
    },
    size: {
      height: 0,
      width: 0,
    },
  },
  player: {
    speed: 0,
    top: 0,
    left: 0,
    size: {
      height: 50,
      width: 300,
    },
    zone: {
      height: 0,
      width: 0,
    },
  },
};

const pong = createSlice({
  name: 'pong',
  initialState,
  reducers: {
    setStatus(state, action) {
      state.status = action.payload;
    },
    setPanel(state, action) {
      state.panel = action.payload;
      state.ball.speed = calcBallSpeedBySize({
        width: action.payload.width,
        height: action.payload.width,
      });
    },
    updateBallSize(state, action) {
      state.ball.size = action.payload.size;
      state.ball.radius = action.payload.radius;
    },
    updateBallDirection(state, action) {
      state.ball.direction = action.payload;
    },
    updateBallPosition(state, action) {
      state.ball.position = action.payload;
      state.ball.midPoint.left =
        action.payload.left + state.ball.size.width / 2;
      state.ball.midPoint.top = action.payload.top + state.ball.size.height / 2;
    },
    updatePlayerPosX(state, action) {
      state.player.left = action.payload;
    },
    updatePlayerSize(state, action) {
      state.player.size = action.payload;
    },
    initGameStats(state) {
      state.lifes = 3;
      state.score = 0;
      state.level = levelsArray[1];
    },
    setNextLevel(state) {
      if (levelsArray[state.level.number + 1] !== undefined) {
        state.level = levelsArray[state.level.number + 1];
        state.status = 'paused';
      } else {
        state.status = 'welcome';
      }
    },
    recordBrickHit(state, action) {
      state.level.matrix[action.payload.index.row][action.payload.index.col]
        .hits++;
      state.score += action.payload.points;

      if (isLevelComplete(state.level)) {
        state.status = 'next-level';
      }
    },
    decreaseLife(state) {
      state.lifes--;

      if (state.lifes === -1) {
        state.status = 'gameover';
      }
    },
  },
});

export default pong.reducer;

export const {
  setStatus,
  setPanel,
  updateBallSize,
  updateBallDirection,
  updateBallPosition,
  updatePlayerPosX,
  updatePlayerSize,
  initGameStats,
  setNextLevel,
  recordBrickHit,
  decreaseLife,
} = pong.actions;
