export const TileColors = [
  '#7D8471', //Cement grey
  '#F8F32B', //Zinc yellow
  '#FF7514', //Pastel orange
  '#1E213D', //Cobalt blue
  '#EA899A', //Light pink
  '#FF2301', //Luminous orange
  '#6C4675', //Blue lilac
  '#A12312', //Tomato red
  '#256D7B', //Water blue
  '#063971' //Traffic blue
]