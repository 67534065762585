import { Outlet } from 'react-router-dom';
import MenuBar from '../menubar/MenuBar';
import LightBackgroundImage from 'assets/LightBackground.webp';
import DarkBackgroundImage from 'assets/DarkBackground.webp';
import 'styles/gameboard.scss';
import useTheme from 'hooks/useTheme';

const Backgrounds = {
  light: LightBackgroundImage,
  dark: DarkBackgroundImage,
};

const MainPanel = () => {
  const theme = useTheme()

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        background: `url('${Backgrounds[theme]}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <MenuBar />
      <div
        className={`mainpaneloutlet ${theme}`}
        style={{
          display: 'flex',
          flex: '1 0',
          marginBottom: '2vh',
          marginLeft: '4vw',
          marginRight: '4vw',
          borderRadius: '20px',
          height: 'calc(100vh - 100px)'
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default MainPanel;
