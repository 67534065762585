import { MathRaceLimitNumber } from "store/reducers/types/games"
import { generateCustomNumbers, getShuffledArray, randomIntFromInterval } from "utils/math"

export type MathRaceQuestion = {
  numberOne: number
  numberTwo: number,
  solution: number,
  answers: number[],
  type: 'add' | 'sub' | 'multi',
  operator: '+' | '-' | 'X'
}

const generateRandomMultiQuestion = (minNumber: number, maxNumber: number) : MathRaceQuestion => {
  const numberOne = randomIntFromInterval(minNumber, maxNumber)
  const numberTwo = randomIntFromInterval(minNumber, maxNumber)
  const solution = numberOne * numberTwo
  const additionalOptions = generateCustomNumbers(minNumber, maxNumber, 3, false, [ solution ])
  const answers = getShuffledArray([...additionalOptions, solution])

  return ({
    numberOne,
    numberTwo,
    solution,
    answers,
    type: 'multi',
    operator: 'X'
  })
}

const generateRandomAddsubQuestion = (minNumber: number, maxNumber: number) : MathRaceQuestion => {
  let numberOne = randomIntFromInterval(minNumber, maxNumber)
  let numberTwo = randomIntFromInterval(minNumber, maxNumber)
  let solution = 0
  const questionType = randomIntFromInterval(0, 1)

  if (numberOne < numberTwo) {
    const tempNumber = numberOne
    numberOne = numberTwo
    numberTwo = tempNumber
  }

  if (questionType === 0) {
    solution = numberOne + numberTwo
  } else {
    solution = numberOne - numberTwo
  }

  const additionalOptions = generateCustomNumbers(minNumber, maxNumber, 3, false, [ solution ])
  const answers = getShuffledArray([...additionalOptions, solution])

  return ({
    numberOne,
    numberTwo,
    solution,
    answers,
    type: questionType === 0 ? 'add' : 'sub',
    operator: questionType === 0 ? '+' : '-'
  })
}

export const generateCustomQuestion = (minNumber: MathRaceLimitNumber, maxNumber: MathRaceLimitNumber) => {
  let question: MathRaceQuestion = null
  const questionType = randomIntFromInterval(0, 1)
  let min = 0
  let max = 0

  if (questionType === 0) {
    min = minNumber.addsub
    max = maxNumber.addsub

    question = generateRandomAddsubQuestion(min, max)
  } else if(questionType === 1) {
    min = minNumber.multi
    max = maxNumber.multi

    question = generateRandomMultiQuestion(min, max)
  }

  return question
}