import { createSlice } from '@reduxjs/toolkit';
import { randomIntFromInterval } from 'utils/math';
import { AuthState } from './types/auth';

const initialState: AuthState = {
  user: {
    gender: randomIntFromInterval(0, 1) === 0 ? 'male' : 'female',
    inventory: {
      diamonds: 100,
      stickers: 30,
    },
    favorites: []
  },
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export default auth.reducer;

export const { setUser } = auth.actions;
