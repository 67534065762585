import { randomIntFromInterval } from "utils/math"
import { TileColors } from "../game/utils"
import { TileType } from "../game/HititTileTypes"

export const LEAF_HEIGHT = 80
export const BOX_LEAF_WIDTH = 100
export const BOX_LEAF_HEIGHT = 100
export const ANIMAL_HEIGHT = 50

export type HitItTileType = {
  tileType: number,
  appearIn: number,
  color: number
} | null

export type BoardProperties = {
  rows: number,
  cols: number,
  elements: number
}

const getRandomTile = () => {
  let tileTypeIndex = 0
  const rarityRange = {
    from: 1,
    to: 12000
  }

  const randomRarity = randomIntFromInterval(rarityRange.from, rarityRange.to)
  const tileType = TileType.findIndex((tile) => tile.rarity.from <= randomRarity && tile.rarity.to >= randomRarity)
  
  tileTypeIndex = tileType !== -1 ? tileType : 0

  return tileTypeIndex
}

export const getBoardValues = () : BoardProperties => {
  const width = window.innerWidth
  const height = window.innerHeight
  let rows = Math.floor(height / BOX_LEAF_HEIGHT) - 2
  let cols = Math.floor(width / BOX_LEAF_WIDTH) - 1

  rows = rows < 8 ? rows : 8//rows - (Math.floor(rows * 0.25))
  cols = cols < 8 ? cols : 8//cols - (Math.floor(cols * 0.25))

  return {
    rows,
    cols,
    elements: Math.floor(rows * cols * 0.33)
  }
}

export const initHitItBoard = (boardProps: BoardProperties) => {
  const numberOfTiles = boardProps.rows * boardProps.cols
  const populatedTiles: number[] = []
  const board: HitItTileType[][] = []

  for (let index = 0 ; index < boardProps.elements ; index++) {
    let foundTile = false
    let randomTile = randomIntFromInterval(0, numberOfTiles - 1)
    
    while (!foundTile) {
      if (!populatedTiles.includes(randomTile)) {
        foundTile = true
        populatedTiles.push(randomTile)
      } else {
        randomTile = randomIntFromInterval(0, numberOfTiles - 1)
      }
    }
  }

  for (let row = 0 ; row < boardProps.rows ; row++) {
    const rowArray = []

    for (let col = 0 ; col < boardProps.cols ; col++) {
      const tileIndex = row * boardProps.cols + col

      if (populatedTiles.includes(tileIndex)) {
        rowArray.push({
          tileType: getRandomTile(),
          appearIn: randomIntFromInterval(500, 4000),
          color: randomIntFromInterval(0, TileColors.length - 1)
        })
      } else {
        rowArray.push(null)
      }
    }

    board.push(rowArray)
  }

  return board
}

export const addNewPopulatedIndex = (board: HitItTileType[][], rows: number, cols: number, hitRow: number, hitCol: number) => {
  board[hitRow][hitCol] = null
  const numberOfTiles = rows * cols
  const hitIndex = hitRow * cols + hitCol
  let foundTile = false
  let randomTile = randomIntFromInterval(0, numberOfTiles - 1)
  let randomRow = Math.floor(randomTile / cols)
  let randomCol = Math.floor(randomTile % cols)

  while (!foundTile) {
    if (randomTile !== hitIndex && board[randomRow][randomCol] === null) {
      foundTile = true
      board[randomRow][randomCol] = {
        tileType: getRandomTile(),
        appearIn: randomIntFromInterval(500, 4000),
        color: randomIntFromInterval(0, TileColors.length - 1)
      }
    } else {
      randomTile = randomIntFromInterval(0, numberOfTiles - 1)
      randomRow = Math.floor(randomTile / cols)
      randomCol = Math.floor(randomTile % cols)
    }
  }
}