import { lazy } from 'react';
import { SuspenseRoute } from './SuspenseRoute';
import MainPanel from 'components/general/MainPanel';

const GamesPage = lazy(() => import('pages/games/index'));
const HitItPage = lazy(() => import('pages/games/HitIt'))
const MathRacePage = lazy(() => import('pages/games/MathRace'))
const PicellPage = lazy(() => import('pages/games/Picell'))
const PongPage = lazy(() => import('pages/games/Pong'))

const GamesRoute = {
  path: '/games',
  element: <MainPanel />,
  children: [
    {
      path: '',
      element:  <SuspenseRoute component={<GamesPage />} />
    },
    {
      path: 'hitit',
      element:  <SuspenseRoute component={<HitItPage />} />
    },
    {
      path: 'mathrace',
      element: <SuspenseRoute component={<MathRacePage />} />
    },
    {
      path: 'picell',
      element: <SuspenseRoute component={<PicellPage />} />
    },
    {
      path: 'pong',
      element: <SuspenseRoute component={<PongPage />} />
    }
  ]
};

export default GamesRoute;