import Snake from 'assets/picell/Snake.png'
import Heart from 'assets/picell/Heart.png'
import Pineapple from 'assets/picell/Pineapple.png'
import Smiley from 'assets/picell/SmileySmall.png'
import Picachu from 'assets/picell/Picachu.png'
import Apple from 'assets/picell/Apple.png'
import Parrot from 'assets/picell/Parrot.png'
import Watermelon from 'assets/picell/Watermelon.png'
import Banana from 'assets/picell/Banana.png'
import Orange from 'assets/picell/Orange.png'
import Cherry from 'assets/picell/Cherry.png'
import Grapes from 'assets/picell/Grapes.png'
import Leamon from 'assets/picell/Leamon.png'
import Strewberry from 'assets/picell/Strewberry.png'
import Bird from 'assets/picell/Bird.png'
import Dog from 'assets/picell/Dog.png'
//import Icecream from 'assets/picell/Icecream.png'
import Pingo from 'assets/picell/Pingo.png'
import Rabbit from 'assets/picell/Rabbit.png'
import Fish from 'assets/picell/Fish.png'

export const PICELL_IMAGES = [
  Snake,
  Heart,
  Pineapple,
  Smiley,
  Picachu,
  Apple,
  Parrot,
  Watermelon,
  Banana,
  Orange,
  Cherry,
  Grapes,
  Leamon,
  Strewberry,
  Bird,
  Dog,
  //Icecream,
  Pingo,
  Rabbit,
  Fish
]
