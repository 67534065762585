export const randomIntFromInterval = (
  min: number,
  max: number,
  exclude: number | null = null,
): number => {
  // min and max included
  if (exclude === null) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  } else {
    let number = Math.floor(Math.random() * (max - min + 1) + min);
    let foundNumber = number !== exclude ? true : false;

    while (!foundNumber) {
      number = Math.floor(Math.random() * (max - min + 1) + min);
      foundNumber = number !== exclude ? true : false;
    }

    return number;
  }
};

export const generateCustomNumbers = (
  min: number,
  max: number,
  size: number,
  canRepeat: boolean,
  exclude: number[] = [],
) => {
  const numbers: number[] = [];

  for (let index = 0; index < size; index++) {
    let foundNumber = false;

    while (!foundNumber) {
      const randomNumber: number = randomIntFromInterval(min, max);

      if (!canRepeat) {
        if (
          !numbers.includes(randomNumber) &&
          !exclude.includes(randomNumber)
        ) {
          foundNumber = true;
          numbers.push(randomNumber);
        }
      } else {
        if (!exclude.includes(randomNumber)) {
          foundNumber = true;
          numbers.push(randomNumber);
        }
      }
    }
  }

  return numbers;
};

export const getShuffledArray = (arr: number[]) => {
  return arr.reduce(
    (newArr, _, i) => {
      const rand = i + Math.floor(Math.random() * (newArr.length - i));
      [newArr[rand], newArr[i]] = [newArr[i], newArr[rand]];
      return newArr;
    },
    [...arr],
  );
};
