import { PongLevel } from './common';
import { CB, DB } from './commonBrickTypes';

export const PongLevel001: PongLevel = {
  number: 1,
  maxRowSize: 8,
  matrix: [
    [DB, DB, DB, DB, DB, DB, DB, DB],
    [DB, CB, CB, CB, CB, CB, CB, DB],
    [DB, CB, CB, CB, CB, CB, CB, DB],
    [DB, CB, CB, CB, CB, CB, CB, DB],
    [DB, CB, CB, CB, CB, CB, CB, DB],
    [DB, CB, CB, CB, CB, CB, CB, DB],
    [DB, DB, DB, DB, DB, DB, DB, DB],
  ],
};
