import { PongLevel } from './common';
import { CB, DB, NA } from './commonBrickTypes';

export const PongLevel002: PongLevel = {
  number: 2,
  maxRowSize: 7,
  matrix: [
    [NA, DB, NA, NA, NA, DB, NA],
    [DB, CB, DB, NA, DB, CB, DB],
    [DB, CB, CB, DB, CB, CB, DB],
    [DB, CB, CB, CB, CB, CB, DB],
    [NA, DB, CB, CB, CB, DB, NA],
    [NA, NA, DB, CB, DB, NA, NA],
    [NA, NA, NA, DB, NA, NA, NA],
  ],
};
