import { RGBAToHexA } from './utils';

export const PIXEL_SIZE = '3.5vh';

export const getMatrixFromImage = (image: HTMLCanvasElement) => {
  const matrix = [];
  const canvas = document.createElement('canvas');
  canvas.width = image?.width;
  canvas.height = image?.height;

  const context = canvas.getContext('2d');
  context.drawImage(image, 0, 0);

  if (canvas?.width > 0) {
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    for (let row = 0; row < image.height; row++) {
      const matrixRow = [];
      for (let col = 0; col < image.width; col++) {
        const index = (row * image.width + col) * 4;
        const red = imageData.data[index];
        const green = imageData.data[index + 1];
        const blue = imageData.data[index + 2];

        matrixRow.push(RGBAToHexA(`rgb(${red},${green},${blue})`));
      }

      matrix.push(matrixRow);
    }
  }

  return matrix;
};

export const drawMatrix = (
  matrix: string[][],
  pixelSize = PIXEL_SIZE,
  cellAction: (row: number, col: number) => void = null,
) => {
  const rows = [];

  if (matrix !== null && matrix !== undefined) {
    for (let row = 0; row < matrix.length; row++) {
      const matrixRow = matrix[row];
      const cells = [];

      for (let col = 0; col < matrixRow.length; col++) {
        const cell = matrix[row][col];

        cells.push(
          <div
            key={`cell-${row}-${col}`}
            onClick={
              cellAction !== null ? () => cellAction(row, col) : undefined
            }
            style={{
              width: pixelSize,
              height: pixelSize,
              maxHeight: '30px',
              maxWidth: '30px',
              border: '1px solid lightgrey',
              backgroundColor: cell,
            }}
          ></div>,
        );
      }

      rows.push(
        <div
          key={`row-${row}`}
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {cells}
        </div>,
      );
    }
  }

  return (
    <div className='picellmatrix'>
      {rows}
    </div>
  );
};

export const getEmptyStateMatrix = (rows: number, cols: number) => {
  const matrix = [];

  for (let row = 0; row < rows; row++) {
    const rowArray = [];

    for (let col = 0; col < cols; col++) {
      rowArray.push('#FFFFFF');
    }

    matrix.push(rowArray);
  }

  return matrix;
};
