import Charm from 'assets/games/hitit/Charm.webp'
import Crocodile from 'assets/games/hitit/Crocodile.webp'
import Elaphant from 'assets/games/hitit/Elaphant.webp'
import Frog from 'assets/games/hitit/Frog.webp'
import Giraffe from 'assets/games/hitit/Giraffe.webp'
import Leopard from 'assets/games/hitit/Leopard.webp'
import Lion from 'assets/games/hitit/Lion.webp'
import Monkey from 'assets/games/hitit/Monkey.webp'
import Rhino from 'assets/games/hitit/Rhino.webp'
import Snake from 'assets/games/hitit/Snake.webp'
import Suricate from 'assets/games/hitit/Suricate.webp'
import Tiger from 'assets/games/hitit/Tiger.webp'
import Unicorn from 'assets/games/hitit/Unicorn02.webp'

interface Props {
  size?: string,
  animal: SupportedAnimals
}

type SupportedAnimals = 
  'charm' |
  'crocodile' |
  'elaphant' |
  'frog' |
  'giraffe' |
  'leopard' |
  'lion' |
  'monkey' |
  'rhino' |
  'snake' |
  'suricate' |
  'tiger' |
  'unicorn'

type AnimalsImages = {
  [key in SupportedAnimals]: string
}

const AnimalsImagesArray: AnimalsImages = {
  charm: Charm,
  crocodile: Crocodile,
  elaphant: Elaphant,
  frog: Frog,
  giraffe: Giraffe,
  leopard: Leopard,
  lion: Lion,
  monkey: Monkey,
  rhino: Rhino,
  snake: Snake,
  suricate: Suricate,
  tiger: Tiger,
  unicorn: Unicorn
}

const HitItAnimalTile = ({size, animal} : Props) => {
  return (
    <img src={AnimalsImagesArray[animal]} style={{
      height: size ?? '1.5vw',
      filter: 'drop-shadow(2px 2px 2px rgb(64, 63, 63))'
    }}/>
  )
}

export default HitItAnimalTile