import { LangDictionay } from 'lang';

export const ENG_DICTIONARY: LangDictionay = {
  inventory: 'Your Bag',
  favorite_games: 'Favorites',
  racing: 'Racing',
  art: 'Art',
  puzzle: 'Puzzle',
  stickers_album: 'Stickers (coming soon)',
  score: 'Score',
  lifes: 'Lifes',
  start: 'Start',
  hit: 'Hit',
  break: 'Break',
  collect: 'Collect',
  next_level: 'Next Level',
  game_over: 'Game Over',
  start_over: 'Start Over',
  level: 'Level',
};
