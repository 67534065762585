import { createSlice } from '@reduxjs/toolkit';
import { SupportedLangs } from 'lang';

export type SupportedThemes = 'dark' | 'light'

interface CommonState {
  theme: SupportedThemes,
  lang: SupportedLangs
}

const getInitialTheme = (): SupportedThemes => {
  const now = new Date();
  let theme: SupportedThemes = 'light';

  if (now.getHours() >= 18) {
    theme = 'dark';
  }

  return theme;
};

const initialState: CommonState = {
  theme: getInitialTheme(),
  lang: 'eng',
};

const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload;
    },
    setLang(state, action) {
      state.lang = action.payload;
    },
  },
});

export default common.reducer;

export const { setTheme, setLang } = common.actions;
