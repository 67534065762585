// types
import { createSlice } from '@reduxjs/toolkit';
import { addNewPopulatedIndex, getBoardValues, initHitItBoard } from 'pages/games/HitIt/logic/elements';
import { generateCustomQuestion } from 'pages/games/MathRace/logic/questions';
import { PICELL_IMAGES } from 'pages/games/Picell/consts/drawingsImages';
import { getEmptyStateMatrix } from 'pages/games/Picell/logic/drawings';
import { randomIntFromInterval } from 'utils/math';
import { GamesState } from './types/games';

const HITIT_GAME_TIME = 60
//const HITIT_MAX_ELEMENTS = 10

const MATHRACE_TIME = 60

// initial state
const initialState: GamesState = {
  hitIt: {
    status: 0,
    score: 0,
    time: HITIT_GAME_TIME,
    lastScore: 0,
    highScore: 0,
    gameSize: {
      rows: 6,
      cols: 6
    },
    board: null,
    boardIsReady: false,
    hits: {},
    test: window.innerWidth
  },
  mathRace: {
    status: 0,
    score: 0,
    time: MATHRACE_TIME,
    lastScore: 0,
    highScore: 0,
    minNumber: {
      addsub: 0,
      multi: 0
    },
    maxNumber: {
      addsub: 100,
      multi: 10
    },
    question: null,
    questionNumber: 0,
    lockAnswer: false,
    correctQuestions: []
  },
  picell: {
    imageIndex: randomIntFromInterval(0, PICELL_IMAGES.length - 1),
    userBoard: null,
    matrix: null,
    currentColor: null
  }
};

const games = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setHitItStatus(state, action) {
      state.hitIt.status = action.payload;
      
      if (action.payload === 1) {
        state.hitIt.time = HITIT_GAME_TIME
        state.hitIt.score = 0
        const boardProps = getBoardValues()
        const initBoard = initHitItBoard(boardProps)
        state.hitIt.gameSize.rows = boardProps.rows
        state.hitIt.gameSize.cols = boardProps.cols
        state.hitIt.board = initBoard
        state.hitIt.boardIsReady = true
        state.hitIt.hits = {}
      } else if (action.payload === 2) {
        state.hitIt.lastScore = state.hitIt.score

        if (state.hitIt.lastScore > state.hitIt.highScore) {
          state.hitIt.highScore = state.hitIt.lastScore
        }
      }
    },
    addToHitItScore(state, action) {
      state.hitIt.score += action.payload;
    },
    onTileHit(state, action) {
      const { row, col } = action.payload
      state.hitIt.hits[state.hitIt.board[row][col].tileType] = state.hitIt.hits[state.hitIt.board[row][col].tileType] === undefined ? 1 : state.hitIt.hits[state.hitIt.board[row][col].tileType] + 1
      addNewPopulatedIndex(state.hitIt.board, state.hitIt.gameSize.rows, state.hitIt.gameSize.cols, row, col)
    },
    setHitItTime(state, action) {
      state.hitIt.time = action.payload
    },
    // ============================= MATH RACE ====================================/
    setMathRaceStatus(state, action) {
      state.mathRace.status = action.payload;
      
      if (action.payload === 1) {
        state.mathRace.time = MATHRACE_TIME
        state.mathRace.score = 0
        state.mathRace.question = generateCustomQuestion(state.mathRace.minNumber, state.mathRace.maxNumber)
        state.mathRace.questionNumber = 1
        state.mathRace.correctQuestions = []
      } else if (action.payload === 2) {
        state.mathRace.lastScore = state.mathRace.score

        if (state.mathRace.lastScore > state.mathRace.highScore) {
          state.mathRace.highScore = state.mathRace.lastScore
        }
      }
    },
    setMathRaceTime(state, action) {
      state.mathRace.time = action.payload
    },
    sendAnswer(state, action) {
      if (action.payload.correct) {
        state.mathRace.score += 10
        state.mathRace.correctQuestions.push(action.payload.question)
      }
      
      state.mathRace.questionNumber++
      state.mathRace.lockAnswer = false
      state.mathRace.question = generateCustomQuestion(state.mathRace.minNumber, state.mathRace.maxNumber)
    },
    setLockAnswer(state) {
      state.mathRace.lockAnswer = true
    },
    // ============================= PICELL ====================================/
    setCellColor(state, action) {
      state.picell.userBoard[action.payload.row][action.payload.col] = state.picell.currentColor
    },
    setCurrentColor(state, action) {
      state.picell.currentColor = action.payload
    },
    initPicellMatrix(state, action) {
      const payloadMatrix = action.payload
      state.picell.matrix = payloadMatrix
      state.picell.userBoard = getEmptyStateMatrix(payloadMatrix.length, payloadMatrix[0].length)
    },
    refreshMatrix(state, action) {
      state.picell.imageIndex = action.payload === -1 ? randomIntFromInterval(0, PICELL_IMAGES.length - 1, state.picell.imageIndex) : action.payload
    },
    clearUserBoard(state) {
      state.picell.userBoard = getEmptyStateMatrix(state.picell.matrix.length, state.picell.matrix[0].length)
    }
  }
});

export default games.reducer;

export const { 
  addToHitItScore, 
  onTileHit, 
  setHitItStatus, 
  setHitItTime, 
  setMathRaceStatus,
  setMathRaceTime,
  sendAnswer,
  setLockAnswer,
  setCellColor,
  setCurrentColor,
  initPicellMatrix,
  refreshMatrix,
  clearUserBoard
} = games.actions;
