import { LangDictionay } from 'lang';

export const HEB_DICTIONARY: LangDictionay = {
  inventory: 'התיק שלך',
  favorite_games: 'מועדפים',
  racing: 'מרוצים',
  art: 'אומנות',
  puzzle: 'פאזל',
  stickers_album: 'מדבקות (בקרוב)',
  score: 'ניקוד',
  lifes: 'חיים',
  start: 'התחל',
  hit: 'פגע',
  break: 'שבור',
  collect: 'אסוף',
  next_level: 'שלב הבא',
  game_over: 'המשחק נגמר',
  start_over: 'שחק שוב',
  level: 'שלב',
};
