import { DICTIONARY, SupportedKeys, SupportedLangs } from 'lang';
import { useSelector } from 'react-redux';
import { IRootState } from 'store/reducers';

const useLang = () => {
  const lang: SupportedLangs = useSelector(
    (state: IRootState) => state.common.lang,
  );

  const getText = (text: SupportedKeys) => {
    return DICTIONARY[lang][text]
  }

  return { lang, getText};
};

export default useLang;