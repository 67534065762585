import { SupportedLangs } from 'lang';
import ENG_IMAGE from 'assets/lang/ENG.webp'
import HEB_IMAGE from 'assets/lang/HEB.webp'

type LangImages = {
  [key in SupportedLangs]: string;
};

export const LANG_IMAGES: LangImages = {
  eng: ENG_IMAGE,
  heb: HEB_IMAGE
}