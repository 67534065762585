import { ColorButton } from 'components/uielements/ColorButton';
import { useDispatch } from 'react-redux';
import { red } from '@mui/material/colors';
import { setLang } from 'store/reducers/common';
import useLang from 'hooks/useLang';
import { LANG_IMAGES } from 'lang/images';

export const LangBotton = () => {
  const dispatch = useDispatch();
  const { lang } = useLang()

  const changeLang = () => {
    if (lang === 'eng') {
      dispatch(setLang('heb'));
    } else {
      dispatch(setLang('eng'));
    }
  };

  return (
    <ColorButton
      colorOne={red[500]}
      colorTwo={red[700]}
      content={
        <img
          src={LANG_IMAGES[lang]}
          className="menu-item"
          style={{
            height: '2vh',
            width: '2.5vh',
          }}
        />
      }
      onClick={changeLang}
    />
  );
};
