import { ColorButton } from 'components/uielements/ColorButton';
import { Link } from 'react-router-dom';
import { pink, lightBlue, lightGreen, cyan } from '@mui/material/colors';
import ImageLogo from 'assets/GameboardLogo.png';
import HomeIcon from 'assets/home/HomeIcon.webp';
import GamesIcon from 'assets/home/ControllerIcon.webp';
import CogIcon from 'assets/home/CogIcon.webp';
import ProfileIcon from 'assets/home/SmileyIcon.webp';
import { ThemeBotton } from './ThemeButton';
import { LangBotton } from './LangButton';

const MenuBar = () => {
  return (
    <div
      style={{
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '8vw',
        paddingRight: '8vw',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1vw',
        }}
      >
        <Link to="/">
          <ColorButton
            colorOne={lightGreen[500]}
            colorTwo={lightGreen[700]}
            content={
              <img
                src={HomeIcon}
                className="menu-item"
                style={{
                  height: '2.5vh',
                  width: '2.5vh',
                }}
              />
            }
          />
        </Link>
        <Link to="/games">
          <ColorButton
            colorOne={lightBlue[500]}
            colorTwo={lightBlue[700]}
            content={
              <img
                src={GamesIcon}
                className="menu-item"
                style={{
                  height: '2vh',
                  width: '2.5vh',
                }}
              />
            }
          />
        </Link>
        <LangBotton />
      </div>
      <div>
        <div>
          <img
            src={ImageLogo}
            className="menu-item"
            style={{
              height: '5vh',
              minWidth: '103px',
              minHeight: '60px',
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1vw',
        }}
      >
        <ThemeBotton />
        <Link to="/profile">
          <ColorButton
            colorOne={cyan[500]}
            colorTwo={cyan[700]}
            content={
              <img
                src={ProfileIcon}
                className="menu-item"
                style={{
                  height: '2.5vh',
                  width: '2.5vh',
                }}
              />
            }
          />
        </Link>
        <Link to="/settings">
          <ColorButton
            colorOne={pink[500]}
            colorTwo={pink[700]}
            content={
              <img
                src={CogIcon}
                className="menu-item"
                style={{
                  height: '2.5vh',
                  width: '2.5vh',
                }}
              />
            }
          />
        </Link>
      </div>
    </div>
  );
};

export default MenuBar;
