import { ColorButton } from 'components/uielements/ColorButton';
import Sun from 'assets/home/Sun.webp';
import Moon from 'assets/home/Moon.webp';
import { useDispatch } from 'react-redux';
import { deepPurple } from '@mui/material/colors';
import { setTheme } from 'store/reducers/common';
import useTheme from 'hooks/useTheme';

const ButtonIcon = {
  light: Sun,
  dark: Moon,
};

export const ThemeBotton = () => {
  const dispatch = useDispatch();
  const theme = useTheme()

  const changeTheme = () => {
    if (theme === 'light') {
      dispatch(setTheme('dark'));
    } else {
      dispatch(setTheme('light'));
    }
  };

  return (
    <ColorButton
      colorOne={deepPurple[500]}
      colorTwo={deepPurple[700]}
      content={
        <img
          src={ButtonIcon[theme]}
          className="menu-item"
          style={{
            height: '2.5vh',
            width: '2.5vh',
          }}
        />
      }
      onClick={changeTheme}
    />
  );
};
