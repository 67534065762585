import { Point, Size } from './types';

/*
  D = SQUARE((x2-x1)^2+(y2-y1)^2)
  D <= Radius = in
  D > Radius = out
*/
export const isBallCollidedWithBox = (
  boxPosition: Point,
  ballPosition: Point,
  ballRadius: number,
  boxSize: Size,
): boolean => {
  for (let x = boxPosition.left; x <= boxPosition.left + boxSize.width; x++) {
    for (let y = boxPosition.top; y <= boxPosition.top + boxSize.height; y++) {
      try {
        const d = Math.sqrt(
          Math.pow(x - ballPosition.left, 2) +
            Math.pow(y - ballPosition.top, 2),
        );

        if (d <= ballRadius) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  return false;
};

export const calcBallSpeedBySize = (size: Size): number => {
  let ballSpeed = 6;

  try {
    const totalPixels = size.height * size.width;
    if (totalPixels >= 1500000) {
      ballSpeed = 3; //NEED TO FIND THE RIGHT FORMULA
    }
  } catch (e) {
    ballSpeed = 6;
  }

  return ballSpeed;
};
