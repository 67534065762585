import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface Props {
  colorOne: string,
  colorTwo: string,
  content: JSX.Element,
  onClick?: () => unknown
}

export const ColorButton = ({colorOne, colorTwo, content, onClick = undefined} : Props) => {
  const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    display: 'flex',
    justifyContent: 'cetner',
    alignItems: 'center',
    color: theme.palette.getContrastText(colorOne),
    borderRadius: '50%',
    width: '4vh',
    height: '4vh',
    minWidth: '30px',
    minHeight: '30px',
    padding: '0px',
    backgroundColor: colorOne,
    '&:hover': {
      backgroundColor: colorTwo,
    },
  }));

  return (
    <CustomButton variant="contained" onClick={onClick ? () => onClick() : undefined}>
      { content }
    </CustomButton>
  )
}